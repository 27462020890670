import React,{ Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import {withRouter} from 'react-router-dom';

import {Auth} from './components/Auth';

// Pages
import Home from './views/Home';
import News from './views/News';
import NewsSingle from './views/NewsSingle';
import AboutUs from './views/AboutUs';
import Reference from './views/Reference';
import Contact from './views/Contact';
import Products from './views/Products';
import Support from './views/Support';
import Admin from './views/Admin';

// Components
import Header from './components/Header';
import Footer from './components/Footer';



// function getLang(){

//     languages = ['sr', 'en'];
//     console.log('pn', window.location.pathname.split('/'));

//     let routeLang = window.location.pathname.split('/')[1]
//     if(languages.includes(routeLang)){
//         localStorage.setItem('psofting_lang', routeLang);
//     }
//     let lang = localStorage.getItem('psofting_lang');
//     Auth.setLang(lang);
//     return lang;
// }
class App extends Component {

  render() {
    return (
      <div className="wrapper">
        <Router basename={`/${Auth.initLang()}`}>
          <Header history={this.props.history} ref={this.header}/>

          <Route exact path="/" component={Home} />
          <Route exact path="/vesti" component={News} />
          <Route exact path="/blog" component={News} />
          <Route exact path="/vest/:id/:slug?" component={NewsSingle} />
          <Route exact path="/news/:id/:slug?" component={NewsSingle} />
          <Route exact path="/o-nama" component={AboutUs} />
          <Route exact path="/about" component={AboutUs} />
          <Route exact path="/reference" component={Reference} />
          <Route exact path="/references" component={Reference} />
          <Route exact path="/kontakt" component={Contact} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/proizvodi" component={Products} />
          <Route exact path="/products" component={Products} />
          <Route exact path="/podrska" component={Support} />
          <Route exact path="/support" component={Support} />
          <Route exact path="/admin" component={Admin} />

          <Footer />
        </Router>
      </div>
    );
  }
}

export default withRouter(props => <App {...props}/>);
