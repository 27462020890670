import React, { Component } from 'react';
import Localtime from '../components/Localtime';

class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() { 
        return (
            <div className="content ">
                <div className="about-us" >
                    <div className="title-about-us">
                        <p>Petrolsoft Ing</p>
                        <span>O nama</span>
                        <h1 className="page-title">Snaga<br/>prave podrške</h1>
                    </div>

                    <div className="about-us-top-content">
                        <div>1991 – 2021</div>
                        <div className="about-us-number">30</div>
                        <div className="about-us-top-content-left">
                            <p>
                                Petrolsoft Ing. uspešno posluje duže od
                                30 godina i zapošljava 65 stručnjaka
                                sa lokalnim i međunarodnim iskustvom.
                            </p>
                        </div>
                    
                        <div className="about-us-top-content-mid">
                            <p>
                                Zahvaljujući ekspertskom poznavanju poslovnih procesa i
                                zahteva klijenata u naftnoj industriji u regionu, postali smo
                                tehnološki i tržišni lider u jugoistočnoj Evropi.
                            </p>

                            <p>
                                Naša osnovna delatnost su End-To-End IT poslovna rešenja u
                                oblasti maloprodaje, naftne industrije i zdravstva.
                            </p>

                            <p>
                                U okviru kompanije uspostavili smo snažne timove za
                                istraživanje i razvoj softvera i hardvera, implementaciju i
                                integraciju IT rešenja, konsalting i servisne usluge.
                            </p>
                        </div>

                        <div className="about-us-top-content-right">
                            <p>
                                Našim klijentima nudimo najnovija tehnološka rešenja,
                                platformu i gotova aplikativna rešenja:
                                POS, BOS, HOS, BI, ERP, CMS i Payment koja su modularna,
                                skalabilna i klijentski orijentisana.
                            </p>

                            <p>
                                Nudimo Desktop, WEB i mobilne aplikacije, Cloud rešenja,
                                SaaS (Software As A Service) i integraciju naših rešenja sa
                                već implementiranim rešenjima – dopunjavanje do „punog
                                paketa".
                            </p>

                            <p>
                                Naravno, uvek smo tu za naše klijente 24 x 7.
                            </p>
                        </div>
                    </div>



                    <div className="about-us-bottom-content">
                        <div>1991 – 2021</div>
                        <div className="about-us-bottom-content-left">
                            <p>
                                Naša kompanija shvatajući značaj, donela je odluku o implementaciji zahteva
                                standarda ISO 9001:2008 i ISO 27001:2015 izvršila je sve neophodne pripeme,
                                počela da primenjuje i da generiše sve zahteve navedenih standarda.
                            </p>

                            <p>
                                Uspešna sertifikacija i izdavanje sertifikata potvrduju da primenjujemo i
                                održavamo sve zahteve Standarda sistema menadžmenta kvalitetom
                                ISO 9001:2008 i sve zahteve Standarda o zaštiti i bezbednosti informacija
                                ISO 27001:2013.
                            </p>

                            <p>
                                ITIL je postao de-facto standard na području IT Service Managementa, i naša
                                kompanija ga uspešno implementira u svom poslovanju.
                            </p>

                            <span>
                                * Sertifikaciju je sprovelo renomirano sertifikaciono telo DAS Certification Ltd Oyt,
                                22A Church St, Rushden, Northamptonshire NN10 9YT, UK.
                            </span>
                        </div>

                        <div className="about-us-bottom-content-right">
                            <div className="about-us-bottom-content-right-left">
                                <p>
                                    ISO 9001--
                                </p>

                                <p>
                                    Sistem menadžmenta kvalitetom je međunarodni standard koji sadrži
                                    zahteve za sistem upravljanja kvalitetom u poslovnoj organizaciji koje
                                    organizacija mora ispuniti da bi uskladila svoje poslovanje sa
                                    međunarodno priznatim normama.
                                </p>

                                <p>
                                    Sistem kvaliteta je upravljački sistem, kojim se dovodi do ostvarenja
                                    postavljenih ciljeva u pogledu kvaliteta poslovanja i pružanja usluga. Ovaj
                                    sistem čine organizaciona struktura, odgovornost subjekta u organizaciji,
                                    procesi i resursi potrebni za upravljanje sistemom.
                                </p>

                                <p>
                                    Serija standarda ISO 9000 predstavlja skup od tri pojedinačna, ali medusobno
                                    povezana standarda.
                                </p>
                            </div>
                        

                            <div className="about-us-bottom-content-right-right">
                                <p>
                                    ISO 27001--
                                </p>

                                <p>
                                    Međunarodni standard koji se odnosi na zaštitu i bezbednost informacija.
                                </p>

                                <p>
                                    Standard podleže različitim područjima primene kao i za razlikovanje mogućih
                                    procesa u organizaciji koji su povezani sa upravljanjem kontrole sigurnosti,
                                    kao što su:
                                </p>

                                <p>
                                    Politika sigurnosti, sigurnost organizacije, kontrola i klasifikacija
                                    izvora, sigurnost osoblja, sigurnost materijalnih dobara i životne sredine,
                                    operativno upravljanje i komunikacija, kontrola pristupa, razvoj i održavanje
                                    raznih sistema i upravljanje kontinuitetom poslovanja.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <Localtime />
            </div>
        );
    }

}

export default AboutUs;