import React, { Component } from 'react';

class Localtime extends Component {

    constructor(props){
        super(props);
        this.state = {
          
        }
    }

    render(){
        return(
           <div className="container-padding localtime">
                <div className="home-localtime">
                    {/* vreme po gradovima */}
                    <div className="localtime-item">
                        <div className="circle">
                            <p>12:34</p>
                        </div>
                        <p>BELGRADE</p>
                        <span>GMT +1</span>
                    </div>
                    <div className="localtime-item">
                        <div className="my-localtime">
                            <p>12:34</p>
                        </div>
                        <p>Vaše vreme</p>
                        <span>GMT +1</span>
                    </div>
                    <div className="localtime-item">
                        <div className="circle">
                            <p>14:34</p>
                        </div>
                        <p>MOSCOW</p>
                        <span>GMT +3</span>
                    </div>
                    <div className="localtime-item">
                        <div className="circle">
                            <p>13:34</p>
                        </div>
                        <p>BUCHAREST</p>
                        <span>GMT +2</span>
                    </div>
                </div>
            </div>
        )
    }

}

export default Localtime;