import React, { Component } from 'react';

class Support extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() { 
        return (
            <div className="content">
                <h1>Support page</h1>
            </div>
        );
    }

}

export default Support;