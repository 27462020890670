import React, { Component } from 'react';

class HomeProducts extends Component {

    constructor(props){
        super(props);
        this.state = {
          
        }
    }

    render(){
        return(
            <div className="home-products">
                <div className="container-padding">
                    <div className="home-middle-content">
                        <div className="left">
                            <h1>Autonomne benzinske stanice</h1>
                            <p>Savremeno poslovno rešenje za smanjenje troškova prodaje i povećanja prometa.</p>
                            <p>Produžite radno vreme vaše benzinske stanice i obezbedite bržu uslugu potrošačima.</p>
                            <p>Ponudite nižu cenu goriva kod samousluživanja i smanjite bezbednosni rizik.</p>
                            <p>Na internim benzinskim stanicama, uspostavite potpunu kontrolu potrošnje goriva.</p>
                        </div>

                        <div className="middle">
                            <ul>
                                <li>
                                    <a href="#">Platni terminal</a>
                                </li>
                                <li>
                                    <a href="#">Proces kontroler</a>
                                </li>
                                <li>
                                    <a href="">BOS i HOS</a>
                                </li>
                                <li>
                                    <a href="#">OMS</a>
                                </li>
                                <li>
                                    <a href="#">AVI i PayWay</a>
                                </li>
                            </ul>
                            <p>Plaćanje bezkontaktnim AVI prstenom ugrađenim na vozilu ili mobilnom aplikacijom </p>

                        </div>

                        <div className="right">
                            {/* SLIKA */}
                            <img src="/v-autonomna-stanica-prava.png" />
                        </div>
                    </div>
                </div>
              
                <div className="home-product-selector">
                    <div className="home-product-selector-left">
                        <img src="/ps-arrow-left.png" />

                        <div>
                            <h4>IT poslovna rešenja</h4>
                            <span>Potpuna automatizacija i optimizacija poslovanja</span>   
                        </div>
                    
                    </div>
                    
                    <div className="home-product-selector-right">
                    <img src="/ps-arrow-right.png" />

                        <div>
                            <h4>Pametnije kartice</h4>
                            <span>Loyalty i kompanijske kartice na pogodnost vernih i novih kupaca</span>
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }

}

export default HomeProducts;