import React, { Component } from 'react';

class Reference extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() { 
        return (
            <div className="content">
                <h1>Reference page</h1>
            </div>
        );
    }

}

export default Reference;