import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Link, Redirect, Read} from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import {Auth} from '../components/Auth';

class Header extends Component {

    constructor(props){
        super(props);
        this.state = {
          lang: Auth.getLang()
        }
    }

    setLang=(event)=>{
        this.setState({lang: event.target.value}, ()=>{
            localStorage.setItem('psofting_lang', event.target.value);
            window.location = window.location.host;
            //console.log(Auth.getLang());
        })
    }

    render(){
        return(
            <div id="header">
                <div className="container-padding">
                    <div className="header-top">
                        <div className="left">
                            <p className="phone">+381 ( 11 ) — 715 2552</p>
                            <div className="select-country">
                                <select>
                                    <option>Srbija</option>
                                    <option>BiH</option>
                                    <option>Hrvatska</option>
                                    <option>Rusija</option>
                                </select>
                            </div>
                        </div>
                        <div className="middle">
                            <img src="/logo.png" alt="petrolsoft logo" />
                            <p>Snaga prave podrške</p>
                        </div>
                        <div className="right">
                            {/* <p>SR</p> */}
                                <select value={this.state.lang} onChange={this.setLang}>
                                    <option value='sr'>sr</option>
                                    <option value='en'>en</option>

                                </select>
                            <Link to="/podrska">Podrška</Link>
                        </div>
                    </div>
                </div>
                
                <div className="header-bottom">
                    <ul>
                        <li className={this.props.location.pathname === '/' ? 'header-active': null}><Link to="/">Početna</Link></li>
                        <li className={this.props.location.pathname === '/o-nama' ? 'header-active': null}><Link to="/o-nama">Petrolsoft Ing</Link></li>
                        <li className={this.props.location.pathname === '/reference' ? 'header-active': null}><Link to="/reference">Reference</Link></li>
                        <li className={this.props.location.pathname === '/proizvodi' ? 'header-active': null}><Link to="/proizvodi">Proizvodi</Link></li>
                        <li className={this.props.location.pathname === '/vesti' ? 'header-active': null}><Link to="/vesti">Vesti</Link></li>
                        <li className={this.props.location.pathname === '/kontakt' ? 'header-active': null}><Link to="/kontakt">Kontakt</Link></li>
                    </ul>
                </div>
            </div>
        )
    }

}

export default withRouter(Header);