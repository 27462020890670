import React, { Component } from 'react';
import {Link} from 'react-router-dom';

class Footer extends Component {

    constructor(props){
        super(props);
        this.state = {
            selectedContact: 0
        }

    }

    selectContact=(event)=>{
        this.setState({selectedContact: event.target.value});
    }

    render(){
        return(
            <div className="footer">
                <div className="container-padding">
                    <div className="footer-left">
                        <div className="footer-left-item">
                            <h2>Petrolsoft</h2>

                            <Link to="/o-nama">O nama</Link>
                            <Link to="/reference">Reference</Link>
                            <Link to="/vesti">Vesti</Link>
                        </div>

                        <div className="footer-left-item">
                            <h2>Izdvojeno</h2>

                            <Link to="#">IT poslovna rešenja</Link>
                            <Link to="#">Autonomne benzinske stanice</Link>
                            <Link to="#">Pametne kartice</Link>
                            <Link to="#">Personalizovani servisi</Link>
                        </div>
                        <img src="./images/v-autonomna-stanica-prava.png" />
                        <div className="footer-left-item">
                            <div className="select-country">
                                <select onChange={this.selectContact} value={this.state.selectedContact}>
                                    <option value="0">Kontakt RUS</option>
                                    <option value="1">Kontakt RS</option>
                                    <option value="2">Kontakt HR</option>

                                </select>
                            

                            {this.state.selectedContact == 0 &&
                                <p><br />Konstantin Tedeev
                                    <br />Head of operations
                                    <br />+7 (903) — 797 08 70
                                    <br /><a href="mailto:konstantin.tedeev@petrolsofting.com" target="_top">konstantin.tedeev@petrolsofting.com</a>
                                </p>

                            }
                            {this.state.selectedContact == 2 &&
                                <p><br />Trgovačka 8,
                                    <br />10000 Zagreb,
                                    <br />+385 1 2409 157,
                                    <br />Fax: +385 1 2409 158, 
                                    <br /><a href="mailto:info@gia.hr" target="_top">info@gia.hr</a>
                                </p>
                            }
                            {this.state.selectedContact == 1 &&
                                <p><br/>Patrijarha Dimitrija 12i,
                                    <br/>11090 Beograd,
                                    <br/>+381 11 715 2552,
                                    <br/><a href="mailto:office@petrolsofting.com" target="_top">office@petrolsofting.com</a>
                                </p>
                            }
                            </div>
                        </div>

                        <div className="footer-left-item">
                            <h2>Podrška</h2>
                        </div>

                        <div className="footer-left-item">
                            <h2>Sertifikati</h2>

                            <Link to="#">ISO 9001:2008</Link>
                            <Link to="#">ISO 27001:2015</Link>
                            <Link to="#">ISO 27001:2013</Link>
                        </div>
                    </div>
                    
                    <div className="footer-right">
                        <img src="/logo.png" alt="petrolsoft logo" />
                        <h2>HQ</h2>
                        <p>Patrijarha Dimitrija 12i</p>
                        <p>11090 Beograd</p>
                        <p>+381 (11) — 715 2552</p>
                        <p>office@petrolsofting.com</p>

                        <a href="#">Facebook</a><br />
                        <a href="#">Instagram</a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer;