import React, { Component } from 'react';
import Localtime from '../components/Localtime';
import renderHTML from 'react-render-html';

let n = [{id: 0, date: '2021-11-22', title: "NEWS 1 TITLE", description: 'some description one', image: 'https://cedarengineeringltd.com/wp-content/uploads/2020/01/petrol-station-CEdar.jpg', content: 'ok neki tekst'}]
        

class News extends Component {
    constructor(props) {
        super(props);
        this.state = {
            news: {id: 0, date: '2021-11-22', title: "Implementacija našeg sistema gazprom romania", description: 'some description one', image: 'https://cedarengineeringltd.com/wp-content/uploads/2020/01/petrol-station-CEdar.jpg',
             content: `<h2>Subtitle of the news</h2>
             <p>Some content text here that is the starting content of the single</p>
             <h3>Some smaller subtitle</h3>
             <p>dolor sit amet, consectetur adipiscing elit. Donec sit amet risus eros. Curabitur ullamcorper mauris nec est tristique, quis commodo ligula dignissim. Curabitur a sem a dolor suscipit bibendum sit amet nec metus. Nulla facilisi. Praesent vitae maximus lacus. Integer venenatis gravida tincidunt. Vivamus neque nisl, suscipit sit amet aliquet sed, aliquet vel lorem. Ut lacus orci, tristique quis eros eget, ornare malesuada urna. Ut porttitor tellus sit amet egestas tincidunt. Nam porta turpis ac tellus consectetur, ac elementum libero egestas. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec posuere tincidunt dignissim. Nam et venenatis eros. Ut mauris lectus, luctus vitae diam quis, gravida</p>
             <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sit amet risus</p>
             <h2>Subtitle of the news Which is very long and goes to another half maybe to the next row</h2>
             <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sit amet risus eros. Curabitur ullamcorper mauris nec est tristique, quis commodo ligula dignissim. Curabitur a sem a dolor suscipit bibendum sit amet nec metus. Nulla facilisi. Praesent vitae maximus lacus. Integer venenatis gravida tincidunt. Vivamus neque nisl, suscipit sit amet aliquet sed, aliquet vel lorem. Ut lacus orci, tristique quis eros eget, ornare malesuada urna. Ut porttitor tellus sit amet egestas tincidunt. Nam porta turpis ac tellus consectetur, ac elementum libero egestas. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec posuere tincidunt dignissim. Nam et venenatis eros. Ut mauris lectus, luctus vitae diam quis, gravida dignissim enim.</p>
             <img src="https://cedarengineeringltd.com/wp-content/uploads/2020/01/petrol-station-CEdar.jpg">
             <p>Some more text under the image</p>`}
        }
    }

    componentDidMount(){
        this.updateNews();
    }

    updateNews = () => {
        //this.setState({news: n[0]}, ()=>{console.log(this.state)})
    }

    render() { 
        return (
            <div className="content">
                <div className="single-news">
                    <div className="single-news-padding">
                        <h5>{this.state.news.date}</h5>
                        <h2 className="single-news-title">{this.state.news.title}</h2>
                    </div>
                        <img className="single-news-featured-image" src={this.state.news.image} />
                    
                    <div className="single-news-padding">
                        <div className="single-news-content">{renderHTML(this.state.news.content)}</div>
                        
                    </div> 
                </div>    
                <Localtime />
            </div>
        );
    }

}

export default News;