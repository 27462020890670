import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch, Link } from 'react-router-dom';
import Localtime from '../components/Localtime';

class News extends Component {
    constructor(props) {
        super(props);
        this.state = {
            news: []
        }
    }

    componentDidMount(){
        this.updateNews();
    }

    updateNews = () => {
        this.setState({news: [
            {id: 1, title: "NEWS 1 TITLE", description: 'some description one', image: 'https://cedarengineeringltd.com/wp-content/uploads/2020/01/petrol-station-CEdar.jpg', content: '<h2>Some inner title</h2><p>some first paragraph</p><p>Lorem Ipsum is essentially placeholder text that is often used by designers to test how their product will look once text has been inputted./p>'},
            {id: 2, title: "Veliki Naslov vesti koji mora preci u dva reda radi testa", description: 'some description two', image: 'https://cedarengineeringltd.com/wp-content/uploads/2020/01/petrol-station-CEdar.jpg', content: '<h2>Some inner title</h2><p>some first paragraph</p><p>Lorem Ipsum is essentially placeholder text that is often used by designers to test how their product will look once text has been inputted./p>'},
            {id: 3, title: "NEWS 3 TITLE", description: 'Jako dugacak description vesti koji bi morao preci u dva reda takodje radi testa i radi formatiranja. Mozda ovaj description ima vise nego jednu recenicu i neke brojeve u sebi?', image: 'https://cedarengineeringltd.com/wp-content/uploads/2020/01/petrol-station-CEdar.jpg', content: '<h2>Some inner title</h2><p>some first paragraph</p><p>Lorem Ipsum is essentially placeholder text that is often used by designers to test how their product will look once text has been inputted./p>'},
            {id: 4, title: "NEWS 4 TITLE", description: 'some description two', image: 'https://cedarengineeringltd.com/wp-content/uploads/2020/01/petrol-station-CEdar.jpg', content: '<h2>Some inner title</h2><p>some first paragraph</p><p>Lorem Ipsum is essentially placeholder text that is often used by designers to test how their product will look once text has been inputted./p>'}
        
        ]}, ()=>{console.log(this.state)})
    }

    render() { 
        return (
            <div className="content">
                <div className="news">
                    <div className="news-top">
                        <h4>Vesti</h4>
                        <h1>Petrolsoft<br/>novosti</h1>
                    
                        <ul className="news-container">
                            {this.state.news.map((item, index)=>{
                                return(
                                    <li>
                                        <img src={item.image}></img>
                                        <div>
                                            <Link to={`/vest/${item.id}`}><h2>{item.title}</h2></Link>
                                            <p>{item.description}</p>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <div className="news-archive">
                        <h2>Arhiva</h2>
                        <ul className="news-archive-years">
                            <li>2021</li>
                            <li>2020</li>
                            <li>2019</li>
                            <li>2018 - 2015</li>
                        </ul>
                        <ul className="news-archive-months">
                            <li>januar</li>
                            <li>jun</li>
                            <li>septembar</li>
                        </ul>
                    </div>
                </div>
                <Localtime />
            </div>
        );
    }

}

export default News;