import React, { Component } from 'react';
import HomeProducts from '../components/HomeProducts';
import Localtime from '../components/Localtime';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() { 
        return (
            <div className="content">
                {/* Top content */}
                <div className="container-padding home-top">
                    <div className="home-top-content">
                        <div className="title">
                            <p>Petrolsoft - Više od 30 godina sa Vama</p>
                            <h1>Poslovno - tehnološka rešenja u naftnoj industriji</h1>
                        </div>

                        <div className="social-buttons">
                            <button>Facebook</button>
                            <button>Instagram</button>
                        </div>

                        <div className="home-top-bottom">
                            <p>Pravi izazovi zahtevaju sjajna rešenja.</p>
                            <p className="paragraph-inline">Predstavljamo fiskalizovane i lokalizovane <b>.Orfej</b> POS BOS module.</p>

                            <button>Saznaj više o Orfej sistemu</button>
                            <div className="line-container">
                            <div className="line"></div>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <HomeProducts />

                {/* Bottom content */}
                <div className="home-bottom-content">
                    <div className="container-padding home-bottom-bottom">
                        <div className="list">
                            <ul>
                                <li>
                                    <a href="#">Petrolsoft</a>
                                </li>
                                <li>
                                    <a href="#">Vesti</a>
                                </li>
                                <li>
                                    <a href="#">Reference</a>
                                </li>
                                <li>
                                    <a href="#">Podrška</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <Localtime />
            </div>
        );
    }

}

export default Home;